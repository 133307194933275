<template>
  <div class="h-100 container-fluid p-0 d-flex flex-column">
    <div class="text-center global-padding pb-3">
      <h2>{{ $t("APPEAL_MANAGEMENT") }}</h2>
    </div>

    <div class="flex-full-height router-view-scrollable-content">
      <div class="pb-5">
        <h4>{{ $t("APPEAL_PROCESS_DESCRIPTION_HEADLINE") }}</h4>
        <img
          src="@/assets/images/new_appeal_process.png"
          class="mx-auto mt-3 d-block appeal-process-img"
          alt="appeal_process"
        />
      </div>

      <div class="pb-5">
        <h4>{{ $t("APPEAL_MANAGEMENT_CURRENT_WAVE_HEADLINE") }}</h4>
        <div class="row justify-content-center w-100">
          <div class="col">
            <p>{{ $t("APPEAL_MANAGEMENT_CURRENT_WAVE_CHANGE") }}</p>
            <Switch
              :initValue="newAppealManagement.continue_with_previous"
              :disabled="activeWaveReadonly"
              v-show="false"
              @change="
                newAppealManagement.continue_with_previous =
                  $event.target.checked;
                updateWave();
              "
            />
            <textarea
              class="form-control appeal-management-textarea mt-3"
              :placeholder="
                $t('APPEAL_MANAGEMENT_CURRENT_WAVE_CHANGE_PLACEHOLDER')
              "
              v-model="newAppealManagement.change_process_text"
              :readonly="activeWaveReadonly"
              @change="updateWave"
            ></textarea>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <p>{{ $t("APPEAL_MANAGEMENT_CURRENT_WAVE_APPROVE") }}</p>
            <ol>
              <li>
                {{ $t("APPEAL_MANAGEMENT_CURRENT_WAVE_APPROVE_LIST_FIRST") }}
              </li>
              <li>
                {{ $t("APPEAL_MANAGEMENT_CURRENT_WAVE_APPROVE_LIST_SECOND") }}
              </li>
              <li>
                {{ $t("APPEAL_MANAGEMENT_CURRENT_WAVE_APPROVE_LIST_THIRD") }}
              </li>
            </ol>
            <!-- <Switch
              :initValue="newAppealManagement.release_approve"
              @change="
                newAppealManagement.release_approve = $event.target.checked;
                updateWave(
                  $event.target.checked,
                  activeWaveConfig,
                  'release_approve'
                );
              "
            /> -->
            <textarea
              class="form-control appeal-management-textarea mt-3"
              :placeholder="
                $t('APPEAL_MANAGEMENT_CURRENT_WAVE_APPROVE_PLACEHOLDER')
              "
              v-show="false"
              v-model="newAppealManagement.release_approve_text"
              @change="updateWave"
              :readonly="activeWaveReadonly"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="appeal-management-button-container global-padding pb-3 pt-3">
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          @click="
            $router.push(
              `/projects/${$route.params.projectGuid}/waves/${$route.params.waveGuid}/dealer-adresses`
            )
          "
        >
          {{ $t("PREVIOUS") }}
        </button>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-primary"
          @click="
            $router.push(
              `/projects/${$route.params.projectGuid}/waves/${$route.params.waveGuid}/reporting-setup`
            )
          "
        >
          {{ $t("NEXT") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const Switch = defineAsyncComponent(() =>
  import("@/components/switches/Switch")
);

export default {
  name: "AppealManagement",
  components: {
    Switch,
  },
  async setup() {
    return {
      newAppealManagement: {
        continue_with_previous: true,
        change_process: false,
        change_process_text: null,
        release_approve: false,
        release_approve_text: null,
      },
    };
  },
  computed: {
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
  methods: {
    async updateWave() {
      this.newAppealManagement.change_process = !this.newAppealManagement
        .continue_with_previous;
      await this.$store.dispatch(
        "waves/setAppealManagement",
        this.newAppealManagement
      );

      let newValue = this.$store.getters["waves/getActiveWaveConfig"];
      this.newAppealManagement.continue_with_previous = !newValue.change_process;
      this.newAppealManagement.change_process = newValue.change_process;
      this.newAppealManagement.change_process_text =
        newValue.change_process_text;
      this.newAppealManagement.release_approve = newValue.release_approve;
      this.newAppealManagement.release_approve_text =
        newValue.release_approve_text;
      this.$forceUpdate();
    },
  },
  created() {
    let activeWaveConfig = this.$store.getters["waves/getActiveWaveConfig"];
    this.newAppealManagement.continue_with_previous = !activeWaveConfig.change_process;
    this.newAppealManagement.change_process = activeWaveConfig.change_process;
    this.newAppealManagement.change_process_text =
      activeWaveConfig.change_process_text;
    this.newAppealManagement.release_approve = activeWaveConfig.release_approve;
    this.newAppealManagement.release_approve_text =
      activeWaveConfig.release_approve_text;
  },
};
</script>

<style scoped>
.appeal-management-textarea {
  resize: none;
  height: 150px;
}

.appeal-management-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.appeal-process-img {
  max-width: 60%;
}

.appeal-characteristics-img {
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .appeal-process-img {
    max-width: 100%;
    width: 100%;
  }
}
</style>
